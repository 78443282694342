import React from 'react';
import { Link } from 'gatsby';

import {
  Nav,
  NavItem,
  TabContent,
  TabPane
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default ({ html }) => {
  const { t } = useTranslation();

  return (
    <section className="section section--gradient documentation">
      <div className="inner">
        <Nav tabs>
          <NavItem>
            <Link
              to="/doc/user-guide"
              className="nav-link">
              {t('pages.documentation.userGuides')}
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/docs/faq"
              className="nav-link">
              {t('pages.documentation.faq')}
            </Link>
          </NavItem>
        </Nav>
      </div>

      <div className="inner content docs">
        <TabContent>
          <TabPane
            className="page-content"
            dangerouslySetInnerHTML={{ __html: html }} />
        </TabContent>
      </div>
    </section>
  );
}