import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Banner from 'components/banner';
import Layout from 'components/layout';
import Content from 'components/content/documentation/standards';


const Standards = ({ data: { pages: { edges: [{ node: content }] } } }) =>
  <Layout>
    <Helmet title={content.frontmatter.title} />
    <Banner {...content.frontmatter} />
    <Content {...content} />
  </Layout>;

export default Standards;

export const query = graphql`
	query StandardsPageQuery ($path: String!) {
        ...MarkdownPages
    }
`